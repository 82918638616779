<template>
  <!-- 资源管理 -->
  <div class="user">
    <!-- 筛选区域 -->
    <div class="screening">
      <el-dialog
        title="查询"
        v-model="theQueryDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="30%"
      >
        <el-form
          label-position="left"
          label-width="100px"
          size="small"
          :model="searchForm"
          ref="upform"
        >
          <el-form-item label="名&emsp;&emsp;称：" prop="">
            <el-input v-model="searchForm.name" size="mini" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="模&emsp;&emsp;块：" prop="">
            <el-select v-model="searchForm.type" placeholder="请选择" size="mini" style="width: 100%">
              <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状&emsp;&emsp;态：" prop="">
            <el-select
              v-model="searchForm.status"
              placeholder="请选择"
              style="width: 100%"
              size="mini"
              clearable
            >
              <el-option
                v-for="(item, index) in ['正常','禁用']"
                :key="index"
                :label="item"
                :value="index"
                size="mini"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button class="cancel" size="mini" @click="searchTapReset" round>重置</el-button>
            <el-button type="primary" size="mini" class="confirmAdd" @click="searchListFun" round>查询</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>资源列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          </div>
          <div class="ml-10">
            <el-button icon="el-icon-plus" class="button-el" size="medium" @click="addToItem()">添加套餐</el-button>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 数据展示 -->
    <el-row>
      <el-col>
        <div style="overflow-x:auto">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            size="mini"
            :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}"
            style="width: 100%;overflow:auto"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
          >
            <el-table-column label="名称">
              <template #default="scope">{{
                scope.row.name == null ? "-" : scope.row.name
              }}</template>
            </el-table-column>
            <el-table-column label="描述">
              <template #default="scope">{{
                scope.row.remark == null ? "-" : scope.row.remark
              }}</template>
            </el-table-column>
            <el-table-column label="价格">
              <template #default="scope">￥{{
                scope.row.price == null ? "-" : scope.row.price
              }}</template>
            </el-table-column>
            <el-table-column label="有效天数">
              <template #default="scope">{{
                scope.row.days == null ? "-" : scope.row.days
              }}</template>
            </el-table-column>
            <el-table-column label="模块">
              <template #default="scope">
                <el-tag>{{ $filter.appIdToModule(scope.row.type,ppJson) }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="分钟数">
              <template #default="scope">{{scope.row.type != 3?scope.row.minutes+'(分钟)':'-'}}</template>
            </el-table-column>
            <el-table-column label="次数">
              <template #default="scope">{{scope.row.type == 3?scope.row.num+'(次)':'-'}}</template>
            </el-table-column>
            <el-table-column label="状态">
              <template #default="scope">{{
                scope.row.status == null ? "-" : scope.row.status===0?'正常':'禁用'
              }}</template>
            </el-table-column>
            <el-table-column label="开始-到期时间" width="260px">
              <template #default="scope">
                <p>开始时间：{{scope.row.createTime == null ? "-" : scope.row.createTime}}</p>
                <p>到期时间：{{scope.row.expireTime == null ? "-" : scope.row.expireTime}}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-tooltip content="编辑套餐" placement="top" effect="light">
                  <el-button 
                    class="editor"
                    size="mini"
                    icon="el-icon-edit"
                    @click="addToItem(scope.row)"
                    round
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除套餐" placement="top" effect="light">
                  <el-button
                    class="deletetable"
                    size="mini"
                    icon="el-icon-delete"
                    round
                    @click="deleteList(scope.row)"
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          background
          :page-sizes="[10, 20, 30, 40]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗→添加/修改 -->
    <div class="pop-view">
      <el-form
        label-position="top"
        label-width="100px"
        :model="item_form"
        ref="item_form"
      >
        <el-dialog
          :title="addOrModify==1?'套餐添加':'套餐修改'"
          v-model="centerItemVisible"
          :close-on-click-modal="false"
          :destroy-on-close="true"
          @close="closeFun()"
          width="50%"
        >
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="8">
                <el-form-item label="名称：">
                  <el-input v-model="item_form.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="描述：">
                  <el-input v-model="item_form.remark"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="价格：">
                  <el-input v-model="item_form.price"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="数量：">
                  <el-input v-model="item_form.stock"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="有效天数：">
                  <el-input v-model="item_form.days"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="模块：">
                  <el-select 
                    v-model="optionsValue" 
                    placeholder="请选择" 
                    style="width: 100%"
                    @change="optionsValueChange" 
                  >
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="optionsValue=='1'">
                <el-form-item label="总分钟数：" >
                  <el-input v-model="item_form.minutes"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="optionsValue!='1'">
                <el-form-item label="总使用次数：" >
                  <el-input v-model="item_form.num"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="状态：">
                  <el-select
                    v-model="item_form.status"
                    placeholder="请选择"
                    style="width: 100%"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in ['正常', '禁用']"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="optionsValue=='1'">
                <el-form-item label="服务商">
                  <el-select
                    v-model="item_form.amountId"
                    placeholder="请选择服务商"
                    style="width: 100%"
                    clearable
                    filterable 
                    @change="changeValues"
                  >
                    <el-option
                      v-for="(item, index) in amount_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="optionsValue=='1'">
                <el-form-item label="接口类型">
                  <el-select
                    v-model="item_form.interfaceId"
                    placeholder="请选择接口类型"
                    style="width: 100%"
                    clearable
                    filterable 
                  >
                    <el-option
                      v-for="(item, index) in interface_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="optionsValue=='1'">
                <el-form-item label="计费类型">
                  <el-select
                    v-model="item_form.billType"
                    placeholder="请选择计费类型"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in [{
                        type:1,
                        name:'单计费'
                      },{
                        type:2,
                        name:'双计费'
                      }]"
                      :key="index"
                      :label="item.name"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="centerItemVisible = false"
                round
                >取消</el-button
              >
              <el-button class="confirmAdd" @click="itemSubmit(addOrModify)" round
                >{{addOrModify==1?'添加':'修改'}}</el-button
              >
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>
import packet from "@/api/web/packet"
import app from '@/api/web/app'
import interfacets from "@/api/open/privacy/interface";
import amountts from "@/api/open/privacy/amount";
import { IconFont } from "@/utils/iconfont";
export default {
  name:"User",
  components:{
    IconFont
  },
  data(){
    return{
      tableData: [], //数据列表
      centerItemVisible: false, // 弹窗--添加及修改
      theQueryDialog: false,
      activeNames: [], // 折叠面板
      tableHeight: "", // 表格高度
      currentPage: 1, // 分页
      total: 0,     // 总数据条数
      loading: true, // loding 状态启用
      addOrModify:'1',  // 添加或修改 (1:添加 2:修改)
      amount_id:[],
      interface_id:[],
      options: [],//模块列表
      optionsValue:'',
      searchForm:{  // 搜索项
        name:'',    // 资源包名称
        remark:'',  // 资源包描述
        price:'',   // 价格
        stock:'',   // 资源包数量
        days:'',    // 有效天数
        type:'',    // 资源包类型 (1:隐私号 2:语音质检 3:黑名单)
        minutes:'', // 包含分钟数
        num:'',     // 包含使用次数
        status:'',  // 资源包状态
        createTime:'',  // 添加时间
        page:1,
        pageSize:10,
      },
      item_form:{  // 搜索项
        name:'',    // 资源包名称
        remark:'',  // 资源包描述
        price:'',   // 价格
        stock:'',   // 资源包数量
        days:'',    // 有效天数
        type:'',    // 资源包类型
        minutes:'', // 包含分钟数
        num:'',     // 包含使用次数
        status:0,  // 资源包状态
        createTime:'',  // 添加时间
        billType:1,
      },
      ppJson:{},
    }
  },
  methods:{
    changeValues(val){
      this.searchForm.interfaceId = ''
      interfacets.getInterface({
        amountId:val
      }).then((res) => {
        this.interface_id = res.data
      });
    },
    // 模块切换
    optionsValueChange(val){
      if(val == 1){
        this.item_form.num = null
        this.item_form.chargeType = 0
      }else{
        this.item_form.minutes = null
        this.item_form.chargeType = 1
      }
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
      });
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
    },
    closeFun(){
      this.item_form = {}
      this.optionsValue = ''
    },
    // guanb
    getLowList(){
        app.appQuery({}).then((res) => {
            this.options = res.data
            let newJson = {}
            res.data.forEach(item => {
                newJson[item.id] = item.name
            });
            this.ppJson = newJson
        })
    },
    // 删除行
    deleteList(row){
        this.$confirm('将删除该套餐, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            packet.deleteUser({
                id:row.id
            }).then(res=>{
                this.$notify({
                    title: '成功',
                    message: '删除成功',
                    type: 'success'
                });
                this.getListData();
            })
        }).catch(() => {
            this.$notify.info({
                title: '通知',
                message: '已取消删除!'
            });
        });
    },
    // 列表添加--提交
    itemSubmit(){
      if(this.addOrModify == 1){
        this.item_form.type = this.optionsValue
        packet.addUser({...this.item_form}).then(res=>{
          if(res.code===200){
            this.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success'
            });
            this.centerItemVisible = false;
            this.getListData();
          }
        })
      }
      if(this.addOrModify == 2){
        this.item_form.type = this.optionsValue
        packet.editUser({...this.item_form}).then(res=>{
          if(res.code===200){
            this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
            });
            this.centerItemVisible = false;
            this.getListData();
          }
        })
      }
    },
    // 列表添加--点击
    addToItem(row){
      if(row == undefined){
        this.addOrModify = 1
        this.centerItemVisible = true;
      }else{
        this.addOrModify = 2
        packet.getUser({
            id:row.id
        }).then(res=>{
            console.log(res)
            this.item_form = res.data
            this.optionsValue = res.data.type+''
            this.centerItemVisible = true;
        })
      }
      
    },
    // 条件筛选--重置
    searchTapReset(kind){
      this.searchForm = {
        page: this.currentPage,
        pageSize: 10,
      };
      if(kind == 1){
        this.getListData();
      }
    },
    // 条件筛选--搜索
    searchListFun(){
      this.theQueryDialog = false
      this.getListData();
    },
    // 分页
    handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.searchForm.pageSize = val
        this.getDataList()
    },
    handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.searchForm.page = val
        this.currentPage =val
        this.getDataList()
    },
    // 获取列表数据
    getListData(){
        this.loading = true;
        packet.listUser({...this.searchForm}).then(res=>{
          this.tableData = res.data.records;
          this.total = res.data.total;
          this.loading = false;
        })
    }
  },
  mounted() {
    this.getListData();
    this.getApiType()
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
    this.getLowList()
  },
}
</script>
<style lang="scss" scoped>
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
</style>